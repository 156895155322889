// Core

@import "core";

// Fonts

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";

//media qqueris
@import "media-queries";

//media qqueris breakpoints
@import "breakpoints";

.btn-primary-bg {
  background: #28a798 !important;
  color: #fff !important;
}
.react-calendar__tile--now {
  background: #e9e9e7 !important;
}
.react-calendar__tile--active {
  background: #093837 !important;
  color: white;
}
.react-calendar {
  width: auto !important;
  border: none !important;
}
.date-range-wrapper > div > div {
  display: block;
}
.d-none {
  display: none;
}
.start-date-input {
  font-size: 1.15rem;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
  color: #093837;
  background: #2630551c;
  flex: 1;
  border: 1px solid transparent;
  cursor: pointer;
  min-width: 1rem;
  &:focus {
    border: 1px solid #093837;
  }
}
.end-date-input {
  font-size: 1.15rem;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  color: #093837;
  background: #2630551c;
  flex: 1;
  border: 1px solid transparent;
  cursor: pointer;
  min-width: 1rem;
  &:focus {
    border: 1px solid #093837;
  }
}
.DayPicker-Month,
.DayPicker {
  width: 100%;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #093837 !important;
  color: #fff !important;
  border-radius: 50px;
}
.DayPicker-Day {
  border-radius: 50px !important;
}
.DayPicker-Day--today {
  color: #093837 !important;
  font-weight: 500 !important;
  border: 1px solid #093837;
  border-radius: 50px;
  display: block !important;
}
.active-time-btn {
  background: #093837 !important;
  border: 1px solid #093837 !important;
  color: white !important;
}
.mr-8 {
  margin-right: 8rem;
}
.no-border {
  border: none;
}
.no-border-bottom {
  border-bottom: none;
}
.house-number-field {
  width: 12.5rem;
  margin-right: 1rem;
}
.white-space-normal {
  white-space: normal;
}
.white-space-no-wrap {
  white-space: nowrap;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track,
.MuiSwitch-track {
  margin-top: -3px;
}
.ticket-details-buttons-wrapper {
  @include media("<=tabletSm") {
    width: 100%;
    margin-bottom: 0 !important;
  }
}
.f-1-15rem {
  font-size: 1.15rem;
}
.f-1-75rem {
  font-size: 1.75rem;
}
.section-1-img {
  max-width: 354px;
}
.section-2-title {
  padding-bottom: 32px;
}
.text-lowercase {
  text-transform: lowercase;
}
.ticket-details-buttons {
  @include media("<=tabletSm") {
    button {
      flex: 1;
    }
  }
}
.offer-job-btn-wrapper {
  margin-left: 0.75rem;
  @include media("<=tabletSm") {
    margin-top: 0.75rem;
    margin-left: 0;
    width: 100%;
  }
}
.ticket-private-btn-wrapper {
  .button {
    height: 36px;
    margin-top: 2px;
    color: #28a798;
  }
  .info-icon {
    margin-top: 0.25rem;
  }
  margin-left: 0.75rem;
  display: flex;
  align-content: center;
}
.text-gray-1 {
  color: #868686;
}
.book-job-close-btn {
  z-index: 100000;
}
.gap-1 {
  gap: 1rem;
}
.mr--75rem {
  margin-right: 0.75rem !important;
}
.position-relative {
  position: relative;
}
.full-screen-modal {
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 1300;
  background: #fafafa;
  overflow-x: auto;
  overflow-y: auto;
}
.row-gap--5 {
  row-gap: 0.5rem;
}
.pt-32 {
  padding-top: 32px;
}
.date-range-wrapper {
  overflow-x: auto;
}
.date-range-wrapper > div {
  box-shadow: none;
}
.time-select-wrapper {
  max-height: 50vh;
  overflow-y: auto;
}
//errors styles
.input-field-error {
  color: $danger;
  margin-top: 0.5em;
}

//text colors
.text-google {
  color: $google;
}

.text-yellow {
  color: $yellow;
}

//borders
.border-yellow {
  border-color: $yellow;
}

//badges
.badge-yellow {
  color: #fff;
  background-color: $yellow;
}
.p-7-imp {
  padding: 7px !important;
}
//buttons
.btn-danger {
  color: #fff !important;
  background-color: $red !important;
}
.btn-success {
  color: #fff !important;
  background-color: $success !important;
}
.btn-warning {
  color: #fff !important;
  background-color: $warning !important;
}
button:disabled {
  opacity: 0.5 !important;
}
.btn-yellow {
  color: #fff !important;
  background-color: $yellow !important;
}
.btn-purple {
  color: #fff !important;
  background-color: $purple !important;
}
//color-danger
.color-danger {
  color: $red;
}
//color-danger important
.color-danger-imp {
  color: $red !important;
}
//width
.width-40vw {
  min-width: 40vw;
}
//background
.bg-white {
  background: white;
}
//link
.link {
  color: #093837;
  text-decoration: none;
  background-color: transparent;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
}
.dashboard-btn {
  font-size: 0.75rem;
}
.w-21rem {
  width: 21rem;
}
//flex
.align-items-center {
  align-items: center !important;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-flex-end {
  justify-content: flex-end;
}
.no-overflow-y {
  overflow-y: hidden;
}
//margins
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.ml-0-imp {
  margin-left: 0 !important;
}
.mr-1,
.mr1 {
  margin-right: 1em;
}

.mr-1-imp,
.mr1-imp {
  margin-right: 1em !important;
}

.mr-2-imp,
.mr2-imp {
  margin-right: 2em !important;
}

.m0 {
  margin: 0;
}

.ml-1,
.ml1 {
  margin-left: 1em;
}

.mh-1 {
  margin: 0 1em;
}
.my-15px {
  margin: 15px 0 !important;
}
//padding
.pt-0 {
  padding: 0;
}
.pt-0-imp {
  padding: 0 !important;
}
.p-0 {
  padding: 0;
}
.pd-4 {
  padding: 1.5rem;
}
.p-0-imp {
  padding: 0 !important;
}
.p1 {
  padding: 1em;
}
.ph-1 {
  padding: 0 1em;
}

.p--5 {
  padding: 0.5em;
}
.p--5-imp {
  padding: 0.5em !important;
}
.pl--5-imp {
  padding-left: 0.5em !important;
}
.pr--5-imp {
  padding-right: 0.5em !important;
}
.pr--2-imp {
  padding-right: 0.2em !important;
}

//wrapping
.no-wrap {
  white-space: nowrap;
}
.d-flex {
  display: flex;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-row-imp {
  flex-direction: row !important;
}
.flex-1 {
  flex: 1;
}
.flex-xs-1 {
  @include responsive(phone) {
    flex: 1;
  }
}
.flex-3 {
  flex: 3;
}
//contact us on landing page
//alignment
.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.container-justify-content-center {
  .MuiGrid-container {
    justify-content: center;
  }
}

//contact us
.contact-us {
  padding: 3em;
  background: white;

  .contact-heading {
    text-align: center;

    h4 {
      font-size: 19px;
      font-weight: 400;
      margin-bottom: 1.5em;
    }
  }

  .contact-content {
    display: flex;
    justify-content: center;

    .text-field {
      display: flex;
      justify-content: space-between;
    }
  }
}

//border colors
$field-border-color: rgba(0, 0, 0, 0.23);
$field-border-color-hover: rgba(0, 0, 0, 0.87);

.border-white {
  border-color: white;
}

.w400 {
  width: 400px;
}

.w100 {
  width: 100%;
}
.max-width-300 {
  max-width: 300px;
}
.verify-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

//colors
.color-red {
  color: $red;
}

.color-primary {
  color: $indigo;
}

//global components media queries
.btn-go-back {
  @include responsive(phone) {
    display: none !important;
  }
}

.pl-5,
.px-5 {
  @include responsive(phone) {
    padding-left: 1rem !important;
  }
}

.pr-5,
.px-5 {
  @include responsive(phone) {
    padding-right: 1rem !important;
  }
}

.pb-5,
.py-5 {
  @include responsive(phone) {
    padding-bottom: 1rem !important;
  }
}

.pt-5,
.py-5 {
  @include responsive(phone) {
    padding-top: 1rem !important;
  }
}

.display-3 {
  @include responsive(phone) {
    font-size: 2rem;
  }
}

.p-5 {
  @include responsive(phone) {
    padding: 1rem !important;
  }
}
.pb-15px {
  padding-bottom: 15px;
}
.p-15px {
  padding: 15px;
}

.slick-prev,
.slick-next {
  @include responsive(smallTab) {
    display: none !important;
  }

  @include responsive(phone) {
    display: none !important;
  }
}

//login-page
.login-page {
  .header {
    display: flex;

    h1 {
      color: #9091ab;
    }
  }
  .register-page {
    width: 90% !important;
    max-width: 600px;
  }
  .full-form {
    display: flex;
    justify-content: space-between;
    width: 80%;
    background-color: white;
    margin: auto;
    margin-top: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    @include media("<=phone") {
      width: 100%;
    }

    .link {
      color: #093837;
    }

    a {
      color: #093837;
    }

    .form-left {
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(242, 248, 255);

      @include responsive(phone) {
        display: none;
      }
    }

    .form-right {
      width: 55%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include responsive(phone) {
        width: 100%;
      }

      .title {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

//flex wrapper
.flex-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include responsive(phone) {
    flex-direction: column;
  }

  div {
    @include responsive(phone) {
      margin: 0;
    }
  }
}

.sidebar-link {
  min-width: 15em;
}

.video-dialog {
  .MuiDialog-paper {
    overflow-y: unset !important;
  }
  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10;
    color: #444;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    transition: background 0.3s;

    &:hover {
      background: rgba(255, 255, 255, 1);
    }
  }
  .video-dialog-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;

    .video-element {
      max-width: 570px;
      max-height: 570px;
      height: auto;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
}

//images wrapper
.image-wrapper {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(60px, auto));

  .image-item {
    height: 60px;
    background-size: cover !important;
  }

  .image-item-viewer {
    height: 60px;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .view-icon {
      opacity: 0;
      transition: all 0.15s linear;
      color: white;
      z-index: 1;
    }

    &::after {
      transition: all 0.15s linear;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      opacity: 0;
      background: linear-gradient(45deg, $black, transparent);
    }

    &:hover {
      cursor: pointer;

      .view-icon {
        opacity: 1;
        transition: all 0.15s linear;
      }

      &::after {
        transition: all 0.15s linear;
        opacity: 1;
      }
    }
  }

  .image-item-picker {
    height: 60px;
    background-size: cover !important;

    border-radius: $border-radius;
    border: 1px solid $field-border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      border-color: $field-border-color-hover;
    }
  }
}
//images wrapper lg
.image-wrapper-lg {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(80px, auto));

  .image-item-lg {
    height: 80px;
    background-size: cover !important;
  }

  .image-item-viewer {
    height: 80px;
    width: 80px;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .delete-button-position {
      left: 10px;
      bottom: 20px;
    }
    .open-button-position {
      top: 20px;
      right: 14px;
    }
    .view-icon {
      opacity: 0;
      transition: all 0.15s linear;
      color: white;
      visibility: hidden;
    }

    &::after {
      transition: all 0.15s linear;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      opacity: 0;
      background: linear-gradient(225deg, $black, transparent);
    }

    &:hover {
      cursor: pointer;

      .view-icon {
        opacity: 1;
        transition: all 0.15s linear;
        z-index: 1;
        visibility: visible;
      }

      &::after {
        transition: all 0.15s linear;
        opacity: 1;
      }
    }
  }
}

.image-wrapper-lg-ticket-details {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(80px, auto));
  min-height: 180px;
  padding: 1rem;
  .image-item-lg {
    height: 80px;
    background-size: cover !important;
  }

  .image-item-viewer {
    height: 80px;
    width: 80px;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .delete-button-position {
      left: 6px;
      bottom: 20px;
    }
    .open-button-position {
      top: 20px;
      right: 12px;
    }
    .view-icon {
      opacity: 0;
      transition: all 0.15s linear;
      color: white;
      visibility: hidden;
    }

    &::after {
      transition: all 0.15s linear;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      opacity: 0;
      background: linear-gradient(225deg, $black, transparent);
    }

    &:hover {
      cursor: pointer;

      .view-icon {
        opacity: 1;
        transition: all 0.15s linear;
        z-index: 1;
        visibility: visible;
      }

      &::after {
        opacity: 1;
      }
    }

    &.selected {
      &::after {
        transition: all 0.15s linear;
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        opacity: 1;
        background: linear-gradient(225deg, $black, transparent);
      }

      .selection-tick {
        visibility: visible;
        opacity: 1;
        position: absolute;
        width: 50%;
        height: 50%;
        z-index: 2;
        color: red;
      }
    }
    .selection-tick {
      visibility: hidden;
      opacity: 0;
      transition: all 0.15s linear;
    }
  }
}
.image-wrapper-lg-ticket-images {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(80px, auto));
  .image-item-lg {
    height: 80px;
    width: 80px;
    background-size: cover !important;
  }
  .image-item-viewer {
    height: 80px;
    width: 80px;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .view-icon {
      opacity: 0;
      transition: all 0.15s linear;
      visibility: hidden;
      color: $purple;
    }

    &::after {
      transition: all 0.15s linear;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      opacity: 0;
      background: linear-gradient(225deg, $black, transparent);
    }

    &:hover {
      cursor: pointer;

      .view-icon {
        opacity: 1;
        transition: all 0.15s linear;
        z-index: 1;
        visibility: visible;
        color: $purple;
        width: 50%;
        height: 50%;
      }

      &::after {
        transition: all 0.15s linear;
        opacity: 1;
      }
    }
  }
}

//labels
label {
  user-select: none;
}

.custom-input-label {
  background: white;
  padding: 0 10px !important;
  margin-left: -5px;
}

//collapsible-table
.collapsible-table {
  display: flex;
  justify-content: space-between;

  .table-area {
    flex: 1;
  }

  .info-area {
    flex: 2;
    margin-left: 1em;
  }
}

//text field-min width
.width-inherit {
  width: inherit;
}

.width-35 {
  width: 35vw;
}
.w-100-imp {
  width: 100% !important;
}
//box-shadow-none
.boxshadow-none {
  box-shadow: none;
}

//.text-align -left
.textalign-left {
  text-align: left;
}

//no-data
.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;

  h2 {
    opacity: 0.3;
    font-size: 1.8rem;
  }
}

//redirtect ot dashboard
.redirect-to-dashboard-wrapper {
  .redirect-to-dashboard {
    left: ($spacer * 2);
    top: ($spacer * 2);
    z-index: 55;
    position: relative !important;
    width: fit-content;
    margin-bottom: 1em !important;
    padding: 5px !important;
    font-size: 0.75rem !important;
    @include responsive(phone) {
      margin-left: -2em !important;
    }
    @include media("<=phone") {
      padding: 5px !important;
      margin-left: -2em !important;
    }
  }
}
.redirect-to-dashboard-wrapper > .MuiButton-root {
  @include media("<=phone") {
    padding: 5px;
  }
}

//stepper horizontal
.MuiStepper-horizontal {
  @include responsive(phone) {
    padding: 8px !important;
  }
}
.color-white {
  color: white;
}
.color-white-imp {
  color: white !important;
}
//timeline
.timeline-list-offset .timeline-item-icon {
  padding-left: 190px !important;
}
.timeline-list-offset:before {
  left: 157px !important;
}
.timeline-list-offset {
  .timeline-item-offset {
    width: 135px;
    text-align: left;
  }
}
.timeline-list {
  overflow: auto;
}
.timeline-list .timeline-item--icon-wrapper {
  top: 0px;
}

.loading-spinner {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1310;
  background: rgba(255, 255, 255, 1);
  @include responsive(lg) {
    width: calc(100% - 270px);
  }
}
.MuiStepButton-horizontal:disabled {
  opacity: 1 !important;
}
//center screen
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  //rating
  .ratingEl {
    font-size: 3.875rem;
  }
}

.MuiIconButton-root {
  padding: 5px !important;
}

.MuiDialog-paperWidthSm {
  max-width: calc(100% - 16px) !important;
  @include media("<=phone") {
    max-width: calc(100% - 16px) !important;
  }
}
.MuiDialog-paperWidthLg {
  @include media(">tablet") {
    width: 100vw !important;
    margin-left: 330px;
    margin-right: 64px;
  }

  @include media(">phone", "<=tablet") {
    width: 100vw !important;
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  @include media("<=phone") {
    width: 100vw !important;
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
}

.MuiDialog-paperScrollPaper {
  margin: 0 !important;
  @include media(">phone", "<=tablet") {
    width: 100% !important;
    margin-left: 64px !important;
    margin-right: 64px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  @include media("<=phone") {
    width: 100% !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  @include media(">tablet") {
    width: 100% !important;
    margin-left: 300px !important;
    margin-right: 64px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
.pb-2rem {
  padding-bottom: 2rem;
}
.Outer-space-margin {
  @include media(">phone", "<=tablet") {
    margin-left: calc(75px - 32px);
    margin-right: calc(75px - 32px);
  }

  @include media("<=phone") {
    margin-left: 8px;
    margin-right: 8px;
  }

  @include media(">tablet") {
    margin-left: calc(64px - 32px);
    margin-right: calc(64px - 32px);
  }
}

.redirect-to-dashboard {
  @include media(">tablet") {
    margin-left: 2rem !important;
  }
  @include media(">phone", "<=tablet") {
    margin-left: 4rem !important;
  }
}

.Outer-space {
  @include media(">phone", "<=tablet") {
    width: calc(100% - 160px) !important;
    flex-basis: calc(100% - 160px) !important;
    margin: auto !important;
  }

  @include media("<=phone") {
    width: calc(100% + 2px) !important;
    flex-basis: calc(100% + 2px) !important;
    margin: auto !important;
  }

  @include media(">tablet") {
    width: calc(100% - 96px) !important;
    flex-basis: calc(100% - 96px) !important;
    margin: auto !important;
  }
}
@media (max-width: 599.98px) {
  .app-content--inner {
    padding: 2rem 0rem 1rem 0rem;
    background: #f9f9f2 !important;
  }
}
//tablet custom padding
.card-content-padding {
  @include media(">phone", "<=tablet") {
    padding: 16px !important;
  }

  @include media(">tablet") {
    padding: 16px !important;
  }
  @include media("<=phone") {
    padding: 16px !important;
  }
}
.LoginCard-content-padding {
  @include media("<=phone", "<=tablet") {
    padding: 16px;
  }
}
.switch-set {
  .MuiSwitch-switchBase {
    top: 4px;
  }
}
.LoginOuterpadding {
  @include media("<=phone", "<=tablet") {
    padding: 16px !important;
  }
}
.button-p {
  padding-left: 32px !important;
  padding-right: 32px !important;
  white-space: nowrap;
}
.button-sm {
  padding-left: 5px !important;
  padding-right: 5px !important;
  white-space: nowrap;
}

.phone-text {
  padding-bottom: 8px;
  padding-left: 2px;
  padding-top: 1px;
  color: rgb(113, 113, 113) !important;
  display: block;
  font-size: 0.75rem !important;
  text-align: left;
}
.mb-2-imp {
  margin-bottom: 2rem !important;
}
//fonts
.f--875rem {
  font-size: 0.875rem !important;
}
.f--75rem {
  font-size: 0.75rem !important;
}
.f-1rem {
  font-size: 1rem !important;
}
.f-20px {
  font-size: 20px !important;
}
.f-125rem {
  font-size: 1.25rem !important;
}
.f-150rem {
  font-size: 1.5rem !important;
}
//font-colors
.font-color-1 {
  color: #093837;
}
.font-color-2 {
  color: rgb(113, 113, 113);
}
.font-color-2imp {
  color: rgb(113, 113, 113) !important;
}
.modal-close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-3-imp {
  margin-top: 3rem !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-bold {
  font-weight: bold !important;
}
.mt-4px {
  margin-top: 4px !important;
}
.mb-4px {
  margin-bottom: 4px !important;
}
.mb-16px {
  margin-bottom: 16px !important;
}
.br-7px {
  border-radius: 7px !important;
}
.p-16px {
  padding: 16px;
}
.font-color-3 {
  color: #093837 !important;
}
.font-color-4 {
  color: rgb(113, 113, 113) !important;
}
.font-color-5 {
  color: red !important;
}

.g-btn > div {
  margin: 0 !important;
  padding: 7px !important;
}
.sub-heading-color {
  color: #717171;
}
.tab-header {
  .MuiPaper-elevation4 {
    box-shadow: none;
    background-color: white;
  }
}
.tab-style {
  background: white;
  box-shadow: 0 0px 1px 0px #dedede;
  border-radius: 5px 5px 0 0;
}

.tab-style-ticket-details {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background: white;
  border-radius: 0.5rem 0.5rem 0 0;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .MuiTab-textColorPrimary.Mui-selected {
    color: #28a798;
    border-radius: 10px 10px 0 0;
    // background-color: #28a798;
  }
}
.no-radius-top {
  border-radius: 0rem 0rem 0.5rem 0.5rem !important;
}

button {
  font-size: 1rem !important;
  font-weight: 500 !important;
}
.MuiButton-outlinedSizeLarge {
  @include media("<=phone") {
    padding: 5px 10px !important;
  }
}
.MuiButton-root {
  @include media("<=phone") {
    padding: 5px 25px !important;
    white-space: nowrap !important;
  }
}

.MuiInputBase-input {
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.MuiInputBase-root {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.MuiGrid-root {
  width: 100%;
}
.mt12-mob {
  @include media("<=phone") {
    margin-top: 12px !important;
  }
}
.MuiDialogTitle-root {
  padding: 16px 0 !important;
}
.MuiDialogContent-root {
  padding: 8px 0 !important;
}
.MuiDialogActions-root {
  padding: 8px 0 !important;
}

.hideon-sm {
  @include media("<=tablet") {
    display: none !important;
  }
}
.textcolor-grey {
  color: gray;
}
.MuiStepLabel-labelContainer {
  .MuiTypography-body2 {
    font-size: 0.75rem !important;
    margin-top: 8px !important;
  }
  .MuiTypography-body1 {
    font-size: 1rem;
    font-weight: 500;
  }
}
.td-align-top {
  vertical-align: top !important;
}
.overflow-x-auto {
  overflow-x: auto;
}

.detail-btn {
  padding: 5px !important;
}

.pb-32px-mob {
  padding-bottom: 32px;
}

.invalid-tenants-screen-wrapper {
  tbody {
    max-height: 540px;
    overflow-y: scroll;
  }
  .main-row {
    td {
      border-bottom: none;
      padding-bottom: 0px;
      padding-top: 8px;
    }
  }
  .error-row {
    padding: 2px;
  }

  .no-errors {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: gray;
    padding: 20px;
  }
}
